<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>InputField Tags</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Input Tag Name *"
                    v-model="inputTag.tagName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Name'"
                    @blur="$v.inputTag.tagName.$touch()"
                    :is-valid="
                      $v.inputTag.tagName.$dirty
                        ? !$v.inputTag.tagName.$error
                        : null
                    "
                  >
                  </CInput>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>
          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import MultiSelect from "../../containers/MultiSelect";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "InputTag",
  components: {
    MultiSelect,
  },
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      checkId: "",
      inputTag: {
        tagId: "0",
        tagName: "",
        priority: "0",
        inputFieldId: this.$route.params.inputFieldId,
      },
    };
  },

  validations: {
    inputTag: {
      tagName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.inputFieldId = this.$route.params.inputFieldId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.tagId = this.$route.params.tagId;
      this.inputFieldId = this.$route.params.inputFieldId;
      this.getTag();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.tagId = this.$route.params.tagId;
      this.inputFieldId = this.$route.params.inputFieldId;
      this.getTag();
    }
  },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      // this.$router.push({ name: 'InputChecks',params: {inputFieldId: this.$route.params.inputFieldId } })
      window.history.back();
    },
    save() {
      this.$v.inputTag.$touch();
      if (this.$v.inputTag.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.inputTag));
      } else {
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/workPermit/sections/InputTag",
          this.inputTag
        ).then((post) => {
          // this.$router.push({ name: "InputChecks",params: {inputFieldId: this.$route.params.inputFieldId }});
          window.history.back();
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getTag() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/workPermit/section/inputTag/" +
          this.tagId
      )
        .then((response) => response.json())
        .then((data) => (this.inputTag = data));
    },
  },
};
</script>
