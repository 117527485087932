<template>
  <CFormGroup class="form-group form-row">
    <template #label>
      <slot name="label">
        <label class="col-form-label col-sm-12 col-md-3">{{ label }}</label>
      </slot>
    </template>
    <template #input>
      <div
        :class="{
          'col-sm-12 col-md-9': true,
          multiselect_error: invalid,
        }"
      >
        <multiselect
          v-model="state"
          @input="input"
          :options="options"
          :multiple="multiple"
          :hide-selected="hideSelected"
          :disabled="disabled"
          :track-by="trackBy"
          :track-ids="trackIds"
          :label="optionLabel"
          :custom-label="customLabel"
        ></multiselect>
        <div class="invalid-feedback">{{ invalidFeedback }}</div>
      </div>
    </template>
  </CFormGroup>
</template>

<script>
export default {
  name: "MultiSelect",
  props: {
    label: String,
    options: Array,
    optionLabel: String,
    trackBy: String,
    trackIds: [String, Array, Number],
    invalidFeedback: String,
    hideSelected: Boolean,
    multiple: Boolean,
    disabled: Boolean,
    invalid: Boolean,
    allowEmpty:Boolean,
    idParamName: { type: String, default: "id" },
    itemTypeisString: { type: Boolean, default: false },
  },
  data() {
    return {
      state: this.updateStateFromIDs(),
    };
  },
  watch: {
    options(newObject, oldObject) {
      this.updateStateFromIDs();
    },
    trackIds(newObject, oldObject) {
      this.updateStateFromIDs();
    },
  },
  mounted() {},
  methods: {
    updateStateFromIDs() {
      console.log("updateStateFromIDs trackIds", JSON.stringify(this.trackIds));
      console.log("updateStateFromIDs options", JSON.stringify(this.options));
      console.log("this.idParamName", this.idParamName);
      if (this.multiple) {
        if (this.options && this.trackIds) {
          // let ids = this.options.filter((tag) =>
          //   this.trackIds.some((id) => id == tag.id)
          // );
          let items = this.options.filter((tag) =>
            this.itemTypeisString
              ? this.trackIds.some((id) => id == tag)
              : this.trackIds.some((id) => id == tag[this.idParamName])
          );
          console.log("updateStateFromIDs items", JSON.stringify(items));
          this.state = items;

          return items;
        }
        return [];
      } else {
        if (this.options && this.trackIds) {
          // let ids = this.options.filter((tag) => this.trackIds == tag.id);

          let items = this.options.filter((tag) =>
            this.itemTypeisString
              ? this.trackIds == tag
              : this.trackIds == tag[this.idParamName]
          );
          console.log("item type string", this.itemTypeisString);
          console.log("trackid", this.trackIds);
          console.log("id param name", this.idParamName);

          console.log("updateStateFromIDs items", JSON.stringify(items));
          if (items.length > 0) {
            this.state = items[0];
            return items[0];
          }
        }
        return null;
      }
    },
    input(args) {
      this.$emit("input", args);
      if (this.multiple) {
        let ids = this.itemTypeisString
          ? this.state
          : this.state.map((value) => value[this.idParamName]);
        console.log("multiselect ids", JSON.stringify(ids));
        this.$emit("update:track-ids", ids);
      } else {
        console.log("multiselect state", JSON.stringify(this.state));
        if (this.state) {
          this.$emit(
            "update:track-ids",
            this.itemTypeisString ? this.state : this.state[this.idParamName]
          );
        } else {
          this.$emit("update:track-ids", null);
        }
      }
    },
  },
};
</script>

<style>
.multiselect_error > div.multiselect > div.multiselect__tags {
  border-color: #e55353;
}
.multiselect_error > div.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}
</style>